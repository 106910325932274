import { Arr, CharEntity, Is, Num } from "core";
import ConstrainedBox = require("Everlaw/UI/ConstrainedBox");
import DateUtil = require("Everlaw/DateUtil");
import Dom = require("Everlaw/Dom");
import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import Input = require("Everlaw/Input");
import Popup = require("Everlaw/UI/Popup");
import { Precision } from "Everlaw/DateTimePrecision";
import { TimezoneN } from "Everlaw/DateUtil";
import TextBox = require("Everlaw/UI/TextBox");
import Type = require("Everlaw/Type");
import UI = require("Everlaw/UI");
import { IconButton } from "Everlaw/UI/Button";
import UI_FocusGrouping = require("Everlaw/UI/FocusGrouping");
import UI_Validated = require("Everlaw/UI/Validated");
import Util = require("Everlaw/Util");
import Widget = require("Everlaw/UI/Widget");

import dojo_on = require("dojo/on");
import * as moment from "moment-timezone";

const DAYS_IN_WEEK = 7;
const DAYS_GRID_ROWS = 6;
const DAYS_GRID_ELEMS = DAYS_IN_WEEK * DAYS_GRID_ROWS;
const MONTHS_GRID_COLS = 4;
const YEARS_GRID_COLS = 4;
const YEARS_GRID_ROWS = 3;
const YEARS_GRID_ELEMS = YEARS_GRID_COLS * YEARS_GRID_ROWS;

const YMD = "YYYY-MM-DD";
const YM = "YYYY-MM";

enum View {
    YEARS = 0,
    MONTHS = 1,
    DAYS = 2,
}

enum StyleNames {
    // calendar
    DATEBOX_CALENDAR = "datebox-calendar",

    // grid
    DAYS_VIEW = "days-view",
    MONTHS_VIEW = "months-view",
    YEARS_VIEW = "years-view",

    // cell
    RANGE_START = "range-start",
    RANGE_MID = "range-mid",
    RANGE_END = "range-end",
    LEFT_EDGE = "left-edge",
    RIGHT_EDGE = "right-edge",
    INVALID = "invalid",

    // bubble
    BUBBLE_SELECTED = "bubble-selected",
    EXTRA_DAY = "extra-day",
}

// While this extends UI.ConstrainedBox, it's not really a ConstrainedBox. See boxType for details.
class DateBox extends ConstrainedBox<Type.DateTime> implements UI.WidgetWithTextBox {
    input: UI_Validated.DateWidget;
    popup: Popup;
    calendar: Calendar;
    fcw: FocusContainerWidget;

    // There are two "date" properties.
    // `selectedDate` is the primary one, corresponding to:
    //   - what value will be return by getValue()
    //   - what value is displayed in the textbox (`selectedDate` is updated when the user types a
    //     a date, but only after the user blurs out of the textbox)
    // `partialDate` is used when the date is "under construction", i.e. when the user has clicked
    // on a year and/or month but hasn't yet clicked a day. Once they finish, `partialDate` is
    // "flushed" to `selectedDate`.
    selectedDate?: FullDate;
    partialDate: PartialDate;
    /**
     * This handler, if present, will be called once a user finishes selecting a date. This means
     * they did one of the following:
     * - typed a date and blurred out of the picker (e.g. clicked outside)
     * - cleared the date and blurred out of the picker
     * - chose a date with the mouse
     *
     * fromKeyboard is true if the handler was triggered by blurring out of the textbox. It's false
     * if the handler was triggered by using the mouse to click a date.
     */
    onSelect?: (val: Type.DateTime, fromKeyboard: boolean) => void;
    private prevOnSelectValue: Type.DateTime;

    private constraints: {
        min?: Date;
        max?: Date;
    };

    /**
     * What timezone this DateBox is in. (Can be changed after instantiation.) That is, if you
     * choose the date 1/1/2000, is that 1/1/2000 in UTC, Pacific Time, ...?
     *
     * The default is UTC.
     *
     * Javascript's Date objects always are in the browser's time zone, so we adjust dates so that
     * they're in the browser's time zone (and therefore actually unequal to the original dates).
     *
     * For example, if this.timezone is Eastern Time (three hours ahead of Pacific) and the browser
     * is in Pacific Time, and you select the date 1/1/2000 Eastern (which would most naturally be
     * represented as 1/1/2000 00:00 Eastern), we actually end up returning the date 1/1/2000 00:00
     * Pacific, which is 1/1/2000 03:00 Eastern.
     *
     * This logic is handled by the adjust() and unadjust() methods.
     */
    timezone: DateUtil.TimezoneN;

    /**
     * Specifies whether you want the calendar to be normal, month year only, or year only
     */
    format: View;

    // format specifies whether you want date only or year only (or regular calendar by default)
    constructor(params?: DateBox.Params) {
        super(params || {});
        this.node = this.input.getNode();
        this.constraints = {};
        this.partialDate = {
            year: null,
            month: null,
            date: null,
        };
        if (!Is.defined(params) || !Is.defined(params.format)) {
            this.format = View.DAYS;
        } else {
            this.format = params.format;
        }
        if (this.format === View.MONTHS) {
            this.partialDate.date = 0;
        } else if (this.format === View.YEARS) {
            this.partialDate.month = 0;
            this.partialDate.date = 0;
        }
        this.calendar = new Calendar(this, params && params.showFutureYears, this.format);
        this.popup = new Popup({
            content: this.calendar.getNode(),
            direction: "after",
            reference: this.node,
            zIndex: "",
            matchWidth: false,
        });
        this.timezone = params?.timezone ?? ("UTC" as TimezoneN);
        UI_FocusGrouping.unite(this.input, this.calendar);
        this.registerDestroyable([this.calendar, this.popup, this.input]);
    }

    /**
     * boxType() should not be called for this class. It implements its own getBox() which does not
     * use boxType().
     *
     * This class is somewhat strange because it inherits from ConstrainedBox in order to use a lot
     * of shared functionality, but the core concept of ConstrainedBox (being backed by a dijit
     * widget, or "box") is not true of this particular subclass.
     *
     * It's definitely possible to refactor the class hierarchy a bit by creating a
     * DijitConstrainedBox class (subclass of ConstrainedBox) and move all the dijit-specific logic
     * there, but it's weird and makes the class hierarchy harder to understand -- this class is
     * really the (only) weird one.
     */
    boxType() {}
    override getBox(params: DateBox.Params) {
        const widgetParams = {
            name: params.placeholder || "date",
            placeholderMessage: params.placeholder,
            required: params.required,
            width: params.width,
            validator: null,
            invalidMessage: params.invalidMessage,
            textBoxAriaLabel: params.textBoxAriaLabel,
            textBoxLabelContent: params.textBoxLabelContent,
            textBoxLabelPosition: params.textBoxLabelPosition,
            customInvalidDateRangeMessage: params.customInvalidDateRangeMessage,
            displayErrorMessage: params.disableBuiltInDisplayError ? () => false : null,
            disableBuiltInDisplayError: params.disableBuiltInDisplayError,
            validateIfTextUnchanged: params.validateIfTextUnchanged,
            invalidRangeMessage: params.invalidRangeMessage,

            onFocus: () => {
                this.getPopupReference();
                this.popup.show();
                this.checkPopupPosition();
                this.calendar.updateCurrentView();
                this.prevOnSelectValue = null;
            },
            onBlur: () => {
                const parsedValue = this.input.getParsedValue();
                if (parsedValue.isValid()) {
                    this.emitOnSelect(this.getValue(), true);
                } else if (this.input.isEmpty()) {
                    this.emitOnSelect(null, true);
                } else {
                    this._setValue(null, {
                        updateCalendar: true,
                        updateInput: false,
                    });
                    this.emitOnSelect(null, true);
                }
                params.onBlur && params.onBlur();
            },
            onChange: (val: any) => {
                this.input.validate();
                const parsedValue = this.input.getParsedValue();
                if (parsedValue.isValid()) {
                    this._setValue(parsedValue, {
                        updateCalendar: true,
                        updateInput: false,
                    });
                } else if (this.input.isEmpty()) {
                    this._setValue(null, {
                        updateCalendar: true,
                        updateInput: false,
                    });
                } else {
                    this.selectedDate = null;
                }
                // I don't love this solution but calling this.popup.positionPopup() doesn't
                // adjust the popup when the error message shows up.
                this.popup.hide();
                this.getPopupReference();
                this.popup.show();
                this.checkPopupPosition();
                // Otherwise, the value is invalid. We want to properly constrain the input, and
                // thus we still updateCalendar.
                params.onChange && params.onChange(this.getValue(), this);
            },
            onSubmit: (val: any) => params.onSubmit && params.onSubmit(val, this),
            inline: Is.defined(params.inline) ? params.inline : true,
        };
        if (params.disableValidation) {
            widgetParams.validator = () => true;
        }
        if (params.format === View.YEARS) {
            this.input = new UI_Validated.YearOnlyWidget(widgetParams);
        } else if (params.format === View.MONTHS) {
            this.input = new UI_Validated.MonthYearWidget(widgetParams);
        } else {
            this.input = new UI_Validated.DateWidget(widgetParams);
        }
        Dom.setAttr(this.input.getNode(), "tabindex", "-1");
        return this.input;
    }

    getPopupReference() {
        this.popup.reference = this.node;
        if (Dom.isHidden(this.input.errorDiv)) {
            this.popup.direction = "after";
        } else {
            this.popup.direction = "before";
        }
    }

    // We want the popup to show up after the textbox when there isn't an error and
    // before the text box when there is but if space constraints force the popup to show up
    // after the text box and there is an error then the attatchment point needs to be changed
    // so it doesn't fall on top of the error message.
    checkPopupPosition() {
        if (this.popup.getPosition() === "after" && !Dom.isHidden(this.input.errorDiv)) {
            if (this.popup.reference !== this.input.errorDiv) {
                this.popup.direction = "after";
                this.popup.reference = this.input.errorDiv;
                this.popup.show();
            }
        } else {
            if (this.popup.reference !== this.node) {
                this.popup.reference = this.node;
                this.popup.show();
            }
        }
    }

    /*
     * isValidYear(, Month, Day): ============================================
     *
     * Given the current .constraints, check if the given year(, month, day) is
     * valid.
     */

    isValidYear(year: number) {
        const min = this.constraints.min;
        const max = this.constraints.max;
        if (min && year < min.getFullYear()) {
            return false;
        }
        if (max && year > max.getFullYear()) {
            return false;
        }
        return true;
    }
    isValidMonth(d: DateWithMonth) {
        const min = this.constraints.min;
        const max = this.constraints.max;
        const minStr = min && moment(min).startOf("month").format(YM);
        const maxStr = max && moment(max).startOf("month").format(YM);
        const dStr = startOf(d).format(YM);
        if (min && dStr < minStr) {
            return false;
        }
        if (max && dStr > maxStr) {
            return false;
        }
        return true;
    }
    isValidDate(d: FullDate) {
        const min = this.constraints.min;
        const max = this.constraints.max;
        const minStr = min && moment(min).format(YMD);
        const maxStr = max && moment(max).format(YMD);
        const dStr = moment().year(d.year).month(d.month).date(d.date).format(YMD);
        if (min && dStr < minStr) {
            return false;
        }
        if (max && dStr > maxStr) {
            return false;
        }
        return true;
    }

    /*
     * Methods for getting and setting the date ==============================
     */

    clear() {
        this.setValue(null);
    }
    override setValue(val: Type.DateTime, silent?: boolean) {
        if (val) {
            this.setFromLong(val.lower);
        } else {
            this._setValue(null, {
                updateCalendar: true,
                updateInput: true,
            });
        }
    }
    setFromLong(l: number) {
        this.setDate(this._fromLong(l));
    }
    _fromLong(l: number): Date {
        if (Is.number(l)) {
            return this.adjust(l);
        }
        return null;
    }
    /**
     * See .timezone
     */
    protected adjust(date: number): Date {
        const browserTz = moment.tz.guess();
        return (
            moment(date)
                // Tell moment what timezone this date is in. This does not change the actual time (unix
                // timestamp).
                .tz(this.timezone)
                // The second argument to .tz means to reinterpret this date as the given timezone (e.g.
                // changing 1/1/01 12am UTC to 1/1/01 12am Pacific). This changes the actual time.
                .tz(browserTz, true)
                .toDate()
        );
    }
    /**
     * The date provided must be in the user's local time zone. Passing null clears the widget.
     */
    setDate(d: Date) {
        this._setValue(moment(d), {
            updateCalendar: true,
            updateInput: true,
        });
    }

    override getValue(timezone = this.timezone) {
        // see DateTime.java for precision (YEAR + MONTH + DAY + ZONE)
        const lower = this.getLongValue(timezone);

        if (lower == null) {
            return null;
        }

        let precision: Precision;
        switch (this.format) {
            case View.DAYS:
                precision = Precision.dateOnly;
                break;
            case View.MONTHS:
                precision = Precision.monthYearOnly;
                break;
            default:
                precision = Precision.yearOnly;
                break;
        }

        return { lower, precision };
    }

    getStringValue(): string {
        return this.input.getValue();
    }
    getLongValue(timezone = this.timezone) {
        const dateobj: Date = this.getDate();
        if (!dateobj) {
            return null;
        }
        return this.unadjust(dateobj.getTime(), timezone);
    }
    /**
     * See .timezone
     */
    protected unadjust(date: number, timezone = this.timezone): number {
        const browserTz = moment.tz.guess();
        // This series of calls is essentially the reverse of in .adjust() -- look at the comments
        // there to see how this works.
        return moment(date).tz(browserTz).tz(timezone, true).toDate().getTime();
    }
    /**
     * Returns the widget's current date, which is midnight (0:00) in the user's local time zone or
     * null (if the date is either not set or the user entered something unparseable)
     */
    getDate(): Date {
        const parsedValue = this.input.getParsedValue();
        if (parsedValue.isValid()) {
            return parsedValue.toDate();
        } else {
            return null;
        }
    }
    _setValue(
        value: moment.Moment | null,
        params: {
            updateCalendar: boolean;
            updateInput: boolean;
        },
    ) {
        let view;
        if (value !== null && value.isValid()) {
            this.partialDate = {
                year: value.year(),
                month: value.month(),
                date: value.date(),
            };
            view = View.DAYS;
            if (params && params.updateInput) {
                this.input.setDate(this.makeDate(), true);
            }
        } else {
            this.partialDate = {
                year: null,
                month: null,
                date: null,
            };
            view = View.YEARS;
            if (params && params.updateInput) {
                this.input.setValue("", true);
            }
        }
        if (params && params.updateCalendar) {
            this.calendar.showView(view);
        }
        this.flushPartialDate();
    }
    _setValuePartial(view: View.YEARS | View.MONTHS, value: number) {
        if (view === View.YEARS) {
            this.partialDate.year = value;
            if (this.format !== View.YEARS) {
                this.calendar.showView(View.MONTHS);
            }
        } else if (view === View.MONTHS && this.format === View.DAYS) {
            this.partialDate.month = value;
            this.calendar.showView(View.DAYS);
        }
    }

    /*
     * Other public methods ==================================================
     */

    override require(isRequired: boolean) {
        this.input.require(isRequired);
    }
    closeDropdown() {
        this.popup.hide();
    }
    setDisplayFormat(format: string) {
        this.input.displayFormat = format;
    }
    setParsingFormat(format: string) {
        this.input.setParsingFormat(format);
    }
    showDayViewFromDate(date: Date) {
        this._setValuePartial(View.YEARS, date.getFullYear());
        this._setValuePartial(View.MONTHS, date.getMonth());
    }
    isEmpty() {
        return this.input.isEmpty();
    }
    override setTextBoxAriaLabel(ariaLabel: string) {
        this.input.setTextBoxAriaLabel(ariaLabel);
    }
    override setTextBoxLabelContent(labelContent: Dom.Content) {
        this.input.setTextBoxLabelContent(labelContent);
    }
    override setTextBoxLabelPosition(position: TextBox.LabelPosition) {
        this.input.setTextBoxLabelPosition(position);
    }

    /*
     * Private methods ===============================================
     */

    flushPartialDate() {
        if (isFullDate(this.partialDate)) {
            this.selectedDate = {
                ...(this.partialDate as FullDate),
            };
        } else {
            this.selectedDate = null;
        }
    }
    private makeDate() {
        if (!isFullDate(this.partialDate)) {
            return;
        }
        return new Date(this.partialDate.year, this.partialDate.month, this.partialDate.date);
    }

    override setMin(value: Type.DateTime, dontAdjust?: boolean) {
        if (!value) {
            this.resetMin();
            return;
        }
        const asDate: Date = dontAdjust
            ? moment(value.lower).toDate()
            : this._fromLong(value.lower);
        this.calendar.firstYear = asDate.getFullYear();
        this.form.setMin(moment(asDate));
        this.constraints["min"] = asDate;
        this.calendar.updateCurrentView();
    }
    override setMax(value: Type.DateTime, dontAdjust?: boolean) {
        if (!value) {
            this.resetMax();
            return;
        }
        const asDate: Date = dontAdjust
            ? moment(value.lower).toDate()
            : this._fromLong(value.lower);
        this.calendar.lastYear = asDate.getFullYear();
        this.form.setMax(moment(asDate));
        this.constraints["max"] = asDate;
        this.calendar.updateCurrentView();
    }
    override resetMin() {
        this.resetMinMax("min");
    }
    override resetMax() {
        this.resetMinMax("max");
    }
    resetMinMax(name: string) {
        if (name === "max") {
            this.form.resetMax();
        } else {
            this.form.resetMin();
        }
        delete this.constraints[name];
        this.calendar.clearUnsetValues();
        this.calendar.updateCurrentView();
    }
    /**
     * This method can be called multiple times, but will only call .onSelect once (in a row) for
     * any date. This is so that if you click a date (which also triggers the blur handler), we only
     * send one onSelect.
     */
    emitOnSelect(val: Type.DateTime, fromKeyboard: boolean) {
        if (!everdateEquals(val, this.prevOnSelectValue)) {
            this.onSelect && this.onSelect(val, fromKeyboard);
            this.prevOnSelectValue = val;
        }
    }

    sendValue(date: PartialDate) {
        let d;
        if (this.format === View.YEARS) {
            d = moment().year(date.year);
        } else if (this.format === View.MONTHS) {
            // must specifiy day of 1, so you keep the same precision as very start of month
            d = moment().year(date.year).month(date.month).date(1);
        } else {
            d = moment().year(date.year).month(date.month).date(date.date);
        }
        this._setValue(d, {
            updateCalendar: false,
            updateInput: true,
        });
        this.input.input.onChange(this.input.getValue());
        this.input.submit(d);
        this.popup.hide();
        this.emitOnSelect(this.getValue(), false);
    }
    toggleInputErrorOutline(isInvalid: boolean): void {
        this.input.toggleErrorOutline(isInvalid);
    }
    // Return null if current input text value is valid else return appropriate invalid message.
    getInputErrorMessageIfInvalid(): Promise<string> {
        return Promise.resolve(this.input.getErrorMessageIfInvalid());
    }
    shouldShowError(): boolean {
        return this.input.shouldShowError();
    }
}

class Calendar extends FocusContainerWidget {
    navBar: NavBar;
    grids: Grid<any>[]; // indexed by View

    firstYear: number;
    view: View;
    format: View;
    datebox: DateBox;

    private hiddenInput: HTMLInputElement;
    constructor(datebox: DateBox, showFutureYears = false, format: View = View.DAYS) {
        super(
            Dom.div({
                class: StyleNames.DATEBOX_CALENDAR + " unselectable",
                tabindex: "-1",
            }),
        );
        this.datebox = datebox;
        if (showFutureYears) {
            this.firstYear = new Date().getFullYear();
        } else {
            this.lastYear = new Date().getFullYear();
        }
        this.view = View.YEARS;
        this.format = format;

        this.hiddenInput = Dom.input();
        const contents = Arr.flat<Dom.Nodeable>(
            Dom.div({ style: { width: 0, height: 0, overflow: "hidden" } }, this.hiddenInput),
            (this.navBar = new NavBar(this)),
            this.initGrids(format),
        );
        this.navBar.update();
        this.showView(this.view);
        this.registerDestroyable([this.grids, this.navBar]);

        Dom.place(contents, this.node);

        this.connect(this.node, "focus", () => {
            this.hiddenInput.focus();
        });
    }
    override onBlur() {
        this.datebox.popup.hide();
    }
    showView(view: View, clearUnset?: boolean) {
        if (!this.canChangeView(view)) {
            return;
        }
        this.view = view;
        this.grids.forEach((grid, i) => {
            Dom.show(grid, i === view);
            if (i === view) {
                grid.update();
            }
        });
        clearUnset && this.clearUnsetValues();
        this.navBar.update();
    }

    updateCurrentView() {
        this.grids[this.view].update();
        this.navBar.update();
    }

    yearsGrid() {
        return this.grids[View.YEARS] as YearsGrid;
    }
    monthsGrid() {
        return this.grids[View.MONTHS] as MonthsGrid;
    }
    daysGrid() {
        return this.grids[View.DAYS] as DaysGrid;
    }
    initGrids(format: View) {
        if (format === View.YEARS) {
            this.grids = [new YearsGrid(this)];
        } else if (format === View.MONTHS) {
            this.grids = [new YearsGrid(this), new MonthsGrid(this)];
        } else {
            // View.DAYS
            this.grids = [new YearsGrid(this), new MonthsGrid(this), new DaysGrid(this)];
        }
        return this.grids;
    }

    get lastYear(): number {
        return this.firstYear + YEARS_GRID_ELEMS - 1;
    }
    set lastYear(year: number) {
        this.firstYear = year - YEARS_GRID_ELEMS + 1;
    }

    clearUnsetValues() {
        if (this.view === View.YEARS) {
            this.datebox.partialDate.year = null;
            this.datebox.partialDate.month = null;
            this.datebox.partialDate.date = null;
        } else if (this.view === View.MONTHS) {
            this.datebox.partialDate.month = null;
            this.datebox.partialDate.date = null;
        } else if (this.view === View.DAYS) {
            this.datebox.partialDate.date = null;
        }
    }

    /**
     * This checks the format of the calendar, thus prohibiting the current view to be changed
     * to something the format prohibits. (e.g. Showing days on a month/year calendar)
     */
    private canChangeView(view: View): boolean {
        if (this.format === View.YEARS) {
            if (view === View.MONTHS || view === View.DAYS) {
                return false;
            }
        } else if (this.format === View.MONTHS) {
            if (view === View.DAYS) {
                return false;
            }
        }
        return true;
    }
}

class NavBar extends Widget {
    private prevArrow: IconButton;
    private nextArrow: IconButton;
    private title: HTMLDivElement;
    private calendar: Calendar;
    constructor(calendar: Calendar) {
        super();

        this.calendar = calendar;

        this.prevArrow = new IconButton({
            iconClass: "chevron-left-20",
            ariaLabel: "previous",
            onClick: () => {
                this.prev();
            },
        });
        this.nextArrow = new IconButton({
            iconClass: "chevron-right-20",
            ariaLabel: "next",
            onClick: () => {
                this.next();
            },
        });

        this.node = Dom.div(
            {
                class: "nav-bar",
            },
            [
                this.prevArrow.node,
                (this.title = Dom.div({ class: "nav-bar-title" }, "--")),
                this.nextArrow.node,
            ],
        );

        this.registerDestroyable([this.prevArrow, this.nextArrow]);
    }
    prev() {
        if (this.calendar.view === View.YEARS) {
            this.calendar.firstYear -= YEARS_GRID_ELEMS;
            this.calendar.yearsGrid().update();
            this.update();
        } else {
            this.calendar.showView(this.calendar.view - 1, true);
        }
    }
    next() {
        if (this.calendar.view === View.YEARS) {
            this.calendar.firstYear += YEARS_GRID_ELEMS;
            this.calendar.yearsGrid().update();
            this.update();
        }
    }
    update() {
        // Set visibility of nextArrow
        Dom.show(this.nextArrow, this.calendar.view === View.YEARS);

        // Set title
        if (this.calendar.view === View.YEARS) {
            const startYear = this.calendar.firstYear;
            const endYear = startYear + YEARS_GRID_ELEMS - 1;
            Dom.setContent(this.title, startYear + CharEntity.NDASH + endYear);
        } else if (this.calendar.view === View.MONTHS) {
            Dom.setContent(this.title, String(this.calendar.datebox.partialDate.year));
        } else if (this.calendar.view === View.DAYS) {
            const month = MonthsGrid.MONTH_NAMES[this.calendar.datebox.partialDate.month];
            const year = this.calendar.datebox.partialDate.year;
            Dom.setContent(this.title, `${month} ${year}`);
        } else {
            Dom.setContent(this.title, "?");
        }
    }
}

/**
 * The data associated with a grid element (a single year, month, or day in a Calendar)
 */
interface GridElement<V> {
    name: string;
    value: V;
}

/**
 * The extra fields defined here (i.e. not in GridElement) are the DOM
 * elements that make up a grid element (a single year, month, or day in a
 * Calendar).
 */
interface GridElementFull<V> extends GridElement<V> {
    /**
     * The <td> containing the other two elements.
     */
    cell: HTMLTableCellElement;

    /**
     * The circle or rounded rectangle that appears on hover (or when a value
     * is selected).
     */
    bubble: HTMLDivElement;

    /**
     * A rectangle that appears behind the bubble. This is used to display
     * ranges. Its exact position (set by CSS) relative to the cell depends on
     * whether it's on the first day of a range, last day, or something in
     * between.
     */
    bgRect: HTMLDivElement;
}

abstract class Grid<V> extends Widget {
    // N.B. for a DaysGrid, elements will include previous and next month
    elements: GridElementFull<V>[];

    private numCols: number;
    private numRows: number;
    private numCells: number;
    private cellHandlers: Util.Destroyable[];

    protected calendar: Calendar;

    constructor(calendar: Calendar, elements: GridElement<V>[], numCols: number) {
        super();
        this.numCols = numCols;
        this.calendar = calendar;

        // These values will actually be properly set by setElements(). While these lines are not actually
        // necessary, they serve as inline documentation.
        this.elements = [];
        this.numRows = 0;
        this.numCells = 0;

        this.node = Dom.table({ class: "grid" });
        this.setElements(elements);
        this.cellHandlers = [];
        this.connect(this.node, Input.tap, (evt) => {
            let target = evt.target as HTMLElement;
            if (target.tagName !== "TD") {
                target = target.parentElement;
            }
            const col = Dom.indexInParent(target);
            const row = Dom.indexInParent(target.parentElement);
            this._click(this.elements[row * this.numCols + col]);
        });
    }
    protected setElements(elements: GridElement<V>[]) {
        this.numRows = Math.ceil(elements.length / this.numCols);
        this.numCells = this.numRows * this.numCols;

        this.clearCellHandlers();
        Dom.empty(this.node);
        this.elements = [];
        for (let r = 0; r < this.numRows; r++) {
            const row = Dom.tr();
            for (let c = 0; c < this.numCols; c++) {
                const idx = r * this.numCols + c;
                const bubble = Dom.div({ class: "bubble" });
                const bgRect = Dom.div({ class: "bg-rect" });
                const cell = Dom.td(bgRect, bubble);

                const elem = {
                    cell,
                    bubble,
                    bgRect,
                    ...elements[idx],
                };
                this.elements.push(elem);

                Dom.setContent(bubble, elem.name);

                if (c === 0) {
                    Dom.addClass(cell, StyleNames.LEFT_EDGE);
                } else if (c === this.numCols - 1) {
                    Dom.addClass(cell, StyleNames.RIGHT_EDGE);
                }

                Dom.place(cell, row);
            }
            Dom.place(row, this.node);
        }
    }
    abstract click(elem: GridElementFull<V>): void;
    _click(elem: GridElementFull<V>) {
        if (!Dom.hasClass(elem.cell, StyleNames.INVALID)) {
            this.click(elem);
        }
    }
    /**
     * Re-render this widget, updating its visible state to match the state contained in its
     * ancestor widgets. (DateBox and Calendar)
     */
    abstract update(): void;

    getCells() {
        return this.elements.map((x) => x.cell);
    }
    getValues() {
        return this.elements.map((x) => x.value);
    }
    getBubbles() {
        return this.elements.map((x) => x.bubble);
    }
    getBgRects() {
        return this.elements.map((x) => x.bgRect);
    }

    /**
     * Add an event listener to a cell (or similar) within the table, such that it will get cleaned
     * up whenever we redraw the table.
     */
    protected cellConnect(target: EventTarget, type: dojo_on.EventType, listener: EventListener) {
        this.cellHandlers.push(dojo_on(target, type, listener));
    }
    private clearCellHandlers() {
        Util.destroy(this.cellHandlers);
        this.cellHandlers = [];
    }
    override destroy() {
        super.destroy();
        Util.destroy(this.cellHandlers);
    }
}

class YearsGrid extends Grid<number> {
    constructor(calendar: Calendar) {
        super(calendar, YearsGrid.getElements(calendar.firstYear), YEARS_GRID_COLS);
        Dom.addClass(this.node, StyleNames.YEARS_VIEW);
    }
    private static getElements(startYear: number) {
        const result = [];
        for (let i = 0; i < YEARS_GRID_ELEMS; i++) {
            const year = startYear + i;
            result.push({
                name: String(year),
                value: year,
            });
        }
        return result;
    }
    update() {
        this.updateElements();
        this.updateForConstraints();
    }
    private updateElements() {
        const myElements = YearsGrid.getElements(this.calendar.firstYear);
        this.setElements(myElements);
    }
    private updateForConstraints() {
        Dom.removeClass(this.getCells(), StyleNames.INVALID);
        const datebox = this.calendar.datebox;
        this.elements.forEach((e) => {
            const year = e.value;
            Dom.toggleClass(e.cell, StyleNames.INVALID, !datebox.isValidYear(year));
        });
    }

    click(elem: GridElementFull<number>) {
        const datebox = this.calendar.datebox;
        datebox.partialDate.year = elem.value;
        datebox._setValuePartial(View.YEARS, elem.value);
        if (datebox.format === View.YEARS) {
            datebox.sendValue(datebox.partialDate);
        }
    }
}

class MonthsGrid extends Grid<number> {
    static MONTH_NAMES = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    static MONTH_ABBRS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    static ELEMENTS = MonthsGrid.MONTH_ABBRS.map((x, i) => ({ name: x, value: i }));

    constructor(calendar: Calendar) {
        super(calendar, MonthsGrid.ELEMENTS, MONTHS_GRID_COLS);
        Dom.addClass(this.node, StyleNames.MONTHS_VIEW);
    }
    click(elem: GridElementFull<number>) {
        const datebox = this.calendar.datebox;
        datebox.partialDate.month = elem.value;
        datebox._setValuePartial(View.MONTHS, elem.value);
        if (datebox.format === View.MONTHS) {
            datebox.sendValue(datebox.partialDate);
        }
    }
    update() {
        this.updateForConstraints();
    }
    private updateForConstraints() {
        const calendar = this.calendar;
        if (!hasYear(calendar.datebox.partialDate)) {
            return;
        }
        const selectedDate = calendar.datebox.partialDate;
        const datebox = this.calendar.datebox;
        this.elements.forEach((elem: GridElementFull<number>) => {
            const d: DateWithMonth = {
                year: selectedDate.year,
                month: elem.value,
            };
            Dom.toggleClass(elem.cell, StyleNames.INVALID, !datebox.isValidMonth(d));
        });
    }
}

type DayInfo = FullDate & { inMonth: boolean };

class DaysGrid extends Grid<DayInfo> {
    constructor(calendar: Calendar) {
        super(calendar, [], DAYS_IN_WEEK);
        Dom.addClass(this.node, StyleNames.DAYS_VIEW);
    }

    private static getDays(d: DateWithMonth): DayInfo[] {
        const JANUARY = 0;
        const DECEMBER = 11;

        const prevMonth = DaysGrid.getOverflowDays(d);
        const currMonth = Arr.range(1, DateUtil.daysInMonth(d), true);
        const n = prevMonth.length + currMonth.length;
        const nextMonth = Arr.range(1, DAYS_GRID_ELEMS - n, true);

        const prev = prevMonth.map((date) => ({
            inMonth: false,
            year: d.month === JANUARY ? d.year - 1 : d.year,
            month: Num.modulo(d.month - 1, 12),
            date: date,
        }));
        const curr = currMonth.map((date) => ({
            inMonth: true,
            year: d.year,
            month: d.month,
            date: date,
        }));
        const next = nextMonth.map((date) => ({
            inMonth: false,
            year: d.month === DECEMBER ? d.year + 1 : d.year,
            month: Num.modulo(d.month + 1, 12),
            date: date,
        }));

        return Arr.flat<DayInfo>(prev, curr, next);
    }

    private static getElements(d: DateWithMonth): GridElement<DayInfo>[] {
        return DaysGrid.getDays(d).map((x) => ({
            name: String(x.date),
            value: x,
        }));
    }
    click(elem: GridElementFull<DayInfo>) {
        const datebox = this.calendar.datebox;
        datebox.partialDate.year = elem.value.year;
        datebox.partialDate.month = elem.value.month;
        datebox.partialDate.date = elem.value.date;
        datebox.sendValue(datebox.partialDate);
    }
    private indexOf(d: number) {
        const selectedDate = this.calendar.datebox.partialDate as DateWithMonth;
        const prevMonth = DaysGrid.getOverflowDays(selectedDate);
        if (d >= 1 && d <= DateUtil.daysInMonth(selectedDate)) {
            return prevMonth.length + d - 1;
        } else {
            return -1;
        }
    }
    private displayRange(start: FullDate, end: FullDate) {
        const startStr = dateToString(start);
        const endStr = dateToString(end);

        this.elements.forEach((e, i) => {
            Dom.removeClass(e.cell, [
                StyleNames.RANGE_START,
                StyleNames.RANGE_MID,
                StyleNames.RANGE_END,
            ]);
            // RANGE_START and RANGE_END look almost identical to BUBBLE_SELECTED, but if we're
            // displaying a range, we don't want to use BUBBLE_SELECTED
            Dom.removeClass(e.bubble, StyleNames.BUBBLE_SELECTED);

            const isStart = dateEquals(e.value, start);
            const isEnd = dateEquals(e.value, end);

            if (isStart && isEnd) {
                Dom.addClass(e.bubble, StyleNames.BUBBLE_SELECTED);
            } else {
                Dom.toggleClass(e.cell, StyleNames.RANGE_START, isStart);
                Dom.toggleClass(e.cell, StyleNames.RANGE_END, isEnd);
            }

            const myDate = dateToString(e.value);
            const isMid = startStr < myDate && myDate < endStr;
            Dom.toggleClass(e.cell, StyleNames.RANGE_MID, isMid);
        });
    }
    private getDateWithMonth() {
        // ptodo should this be selectedDate or partialDate
        const datebox = this.calendar.datebox;
        if (!datebox.partialDate || !hasMonth(datebox.partialDate)) {
            return;
        }
        return datebox.partialDate;
    }
    update() {
        const selectedDate = this.getDateWithMonth();
        if (!selectedDate) {
            return;
        }
        this.updateElements();
        this.updateHoverHandlers();
        this.updateGrays();
        this.updateForConstraints();
        this.updateSelected();
        this.updateSelectedRange();
    }
    private updateElements() {
        const selectedDate = this.getDateWithMonth();
        if (!selectedDate) {
            return;
        }
        this.setElements(DaysGrid.getElements(selectedDate));
    }
    private updateHoverHandlers() {
        if (this.calendar.datebox.rwInfo) {
            this.elements.forEach((elem) => {
                // A hover handler for each individual day.
                this.cellConnect(elem.cell, "mouseover", () => {
                    const datebox = this.calendar.datebox;
                    if (datebox.isValidDate(elem.value)) {
                        this.updateSelectedRange(elem.value);
                    } else {
                        this.updateSelectedRange();
                    }
                });
            });
            this.cellConnect(this.node, "mouseleave", () => {
                // An unhover handler for this whole DaysGrid.
                this.updateSelectedRange();
            });
        }
    }
    /**
     * Make "extra days" from the prev/next month (e.g. April 29-30 and June 1-2 here) gray.
     *
     *         May 2018
     *   Su Mo Tu We Th Fr Sa
     *   29 30  1  2  3  4  5
     *    6  7  8  9 10 11 12
     *   13 14 15 16 17 18 19
     *   20 21 22 23 24 25 26
     *   27 28 29 30 31  1  2
     */
    private updateGrays() {
        const selectedDate = this.getDateWithMonth();
        if (!selectedDate) {
            return;
        }
        DaysGrid.getDays(selectedDate).forEach((x, i) => {
            Dom.toggleClass(this.elements[i].bubble, StyleNames.EXTRA_DAY, !x.inMonth);
        });
        return;
    }
    private updateForConstraints() {
        const selectedDate = this.getDateWithMonth();
        if (!selectedDate) {
            return;
        }
        const datebox = this.calendar.datebox;
        DaysGrid.getDays(selectedDate).forEach((x, i) => {
            const e = this.elements[i];
            const d = {
                year: x.year,
                month: x.month,
                date: x.date,
            };
            Dom.toggleClass(e.cell, StyleNames.INVALID, !datebox.isValidDate(d));
        });
    }
    private updateSelected() {
        const selectedDate = this.getDateWithMonth();
        if (!selectedDate) {
            return;
        }
        Dom.removeClass(this.getBubbles(), StyleNames.BUBBLE_SELECTED);
        if (isFullDate(selectedDate)) {
            const bubble = this.elements[this.indexOf(selectedDate.date)].bubble;
            Dom.addClass(bubble, StyleNames.BUBBLE_SELECTED);
        }
    }
    /**
     * If this DateBox is part of a RangeWidget, AND
     * the other endpoint of the range has been set, AND
     * this DateBox's date has been set or the optional mySelectedDate argument has been given,
     * then display the range.
     */
    private updateSelectedRange(mySelectedDate = this.getDateWithMonth()) {
        const datebox = this.calendar.datebox;
        if (!datebox.rwInfo || !mySelectedDate || !isFullDate(mySelectedDate)) {
            return;
        }

        const other = datebox.rwInfo.other as DateBox;
        if (!other.selectedDate || !isFullDate(other.selectedDate)) {
            return;
        }
        if (datebox.rwInfo.isBegin) {
            this.displayRange(mySelectedDate, other.selectedDate);
        } else {
            this.displayRange(other.selectedDate, mySelectedDate);
        }
    }

    private static getOverflowDays(d: DateWithMonth) {
        const currMonthStart = moment(new Date(d.year, d.month, 1));
        const startDay = 0; // sunday
        const numOverflowDays = Num.modulo(currMonthStart.day() - startDay, 7);
        const prevMonthEndDate = moment(currMonthStart).subtract(1, "day").date();
        const firstOverflowDay = prevMonthEndDate - numOverflowDays + 1;
        const result = [];
        for (let i = 0; i < numOverflowDays; i++) {
            result.push(firstOverflowDay + i);
        }
        return result;
    }
}

/*
 * Various types and utility functions for dealing with dates ================
 *
 * These are pretty specific to DateBox, so it probably wouldn't make sense to
 * put them in DateUtil.ts.
 */

/**
 * A PartialDate can also be a complete date (if all three fields are filled in)
 */
interface PartialDate {
    year?: number;
    /**
     * Month is zero-indexed; e.g. January = 0, not 1.
     */
    month?: number;
    date?: number;
}

interface DateWithYear extends PartialDate {
    year: number;
}
interface DateWithMonth extends DateWithYear {
    month: number;
}
interface FullDate extends DateWithMonth {
    date: number;
}

function hasYear(d: PartialDate): d is DateWithYear {
    return Is.number(d.year);
}
function hasMonth(d: PartialDate): d is DateWithMonth {
    return Is.number(d.year) && Is.number(d.month);
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function hasDate(d: PartialDate): d is FullDate {
    return isFullDate(d);
}
function isFullDate(d: PartialDate): d is FullDate {
    return Is.number(d.year) && Is.number(d.month) && Is.number(d.date);
}

function dateToString(d: FullDate) {
    return moment().year(d.year).month(d.month).date(d.date).format(YMD);
}

function dateEquals(d1: FullDate, d2: FullDate) {
    return d1.year === d2.year && d1.month === d2.month && d1.date === d2.date;
}

function everdateEquals(d1: Type.DateTime, d2: Type.DateTime) {
    if (Is.object(d1) && Is.object(d2)) {
        return d1.lower === d2.lower;
    } else if (!Is.object(d1) && !Is.object(d2)) {
        return false;
    } else {
        return false;
    }
}

function startOf(d: DateWithMonth) {
    return moment(new Date(d.year, d.month, 1));
}

module DateBox {
    export interface Params extends ConstrainedBox.Params, UI.WidgetWithTextBoxParams {
        // If true, show the current year as the first year in the calendar grid rather than the last.
        // This means that the other years shown will be future, rather than past.
        showFutureYears?: boolean;
        // for specifiying if you want the calendar to be year only, date only, or default date
        format?: View;
        required?: boolean;
        // don't use Validated's built in validation
        disableValidation?: boolean;
        inline?: boolean;
        customInvalidDateRangeMessage?: string;
        // do nothing in ValidatedTextBox's displayError function
        disableBuiltInDisplayError?: boolean;
        invalidRangeMessage?: string;
    }

    /*
     * Same as Views declared above
     */
    export enum View {
        YEARS,
        MONTHS,
        DAYS,
    }
}

export = DateBox;
